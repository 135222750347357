.top-menu {
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    min-height: 14vh;
    justify-content: flex-end;
    gap: 20px;
}

.go-back-btn {
    color: var(--highlightedText);
    border: 1px solid var(--highlightedText);
    margin-right: 10px;
    width: 2vw;
    height: 2vw;
    border-radius: 3px;
    padding: 2px;
}

.go-back-btn:hover {
    background-color: var(--highlightedText);
    color: var(--font);
}

.top-menu-btn {
    border: 1px solid var(--highlightedText);
    color: var(--highlightedText) ;
    margin-left: 15px;
    height: 2vw;
    border-radius: 3px;
    padding: 2px 15px;
    font-size: var(--fontBase);
}

.top-menu-btn:hover{
    color: var(--font) !important;
    border-color: var(--font);
}

.is-active{
    color: var(--font);
    background-color: var(--highlightedText);
}

.is-active:hover{
    color: var(--font);
}