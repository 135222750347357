.top-section {
    height: 17vh;
    width: 100%;
    margin-top: 2vh;
}

.top-section > div {
    height: 100%;
}

.has-line {
    border-bottom: 1px solid var(--highlightedText);
}

.is-dashed {
    color: var(--highlightedText);
    background-image: linear-gradient(to right, var(--highlightedText) 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 8px 2px;
    background-repeat: repeat-x;
}


.bottom-section {
    width: 100%;
    transition: width 1.5s ease, transform 1.5s ease;
    margin-top: 3vh;
    height: 78vh;
    overflow: hidden;
}

.bottom-section-content{
    width: 100%;
    min-height: 75vh;
    display: flex;
    gap: 2vh;
}

.expanded {
    transform: translateX(-17vw);
    width: 92vw;
}