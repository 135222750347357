.agents-content {
    height: 16vh;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.agents-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    
}

.agents {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
}

/* .divisor {
    border-right: solid 1px #EA661B;
    height: 40px;
} */

.horizontal-label {
    display: flex;
    flex-direction: column;
    font-weight: bold;

}

.horizontal-upper-label {
    font-size: var(--fontMedium);
    font-family: Helvetica;
    font-weight: bold;
    color: var(--highlightedText);
}

.horizontal-bottom-label{
    font-size: var(--fontMedium);
    font-family: Helvetica light;
    font-weight: normal;
    line-height: 1;
    color: var(--highlightedText);
}

.horizontal-value {
    font-family: "aglet-mono", mono;
    color: var(--highlightedText);
    font-size: var(--fontExtraLarge);
}


.vertical-agents{
    display: flex;
    color: var(--highlightedText);
    gap: 10px;
    align-items: center;
    padding-left: 5px;
    height: 50px;
}

.vertical-number{
    font-family: 'IBM plex sans';
    font-size: var(--fontLarge);
}

.vertical-label{
    display: flex;
    flex-direction: column;
    font-size: var(--fontBase);
    line-height: 1;
    gap: 2px;
    
}

.vertical-upper-label{
    font-family: 'Helvetica neue';
    font-weight: bold;

}

.vertical-bottom-label{
    font-family: 'Helvetica neue';
    font-weight: 300;
    line-height: 1;
}