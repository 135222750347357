.list-view-container {
    position: relative;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.settings-table {
    height: 30px;
    background-color: var(--background);
}

.table {
    overflow: scroll;
    height: 100%;
    margin-top: 5px;

}

#columnsMenu {
    overflow: scroll;
    height: 400px;
    position: absolute;
    background-color: var(--background);
    padding: 20px;
    overflow-x: hidden;
    z-index: 2;
}

.table-settings {
    padding: 0px;
}

#columnsMenu div {
    gap: 8px;
    display: flex;
}

th {
    background-color: var(--background) !important;
    color: var(--highlightedText) !important;
    font-family: 'Helvetica light';
}

td {
    background-color: var(--background) !important;
    color: var(--font) !important;
    overflow: hidden !important;
    height: 40px !important;
    border-bottom: 1px solid var(--highlightedText);
    font-family: "IBM plex mono";
    cursor: pointer;
}

.table-container {
    position: relative;
    overflow-y: auto;
    overflow-x: auto;
}

.table-container table {
    width: 100%;
    border-collapse: collapse;
    height: auto;
}

.table-container thead th {
    position: sticky;
    top: 0;
    background-color: var(--background);
    z-index: 1;
    font-weight: 100;
    font-family: "Helvetica";
}

.table-container tbody td {
    max-height: 20px !important;
    overflow: hidden !important;
    white-space: nowrap;
}

.table-container .ctable {
    cursor: pointer;
}

.table-hover>tbody>tr:hover>* {
    --cui-table-bg-state: var(--highlightedRow) !important;
}

.table-dark {
    --cui-table-striped-bg: #142A33 !important;
}





/* Estilos para los checkbox */


.checkbox-container {
    display: inline-block;
    vertical-align: middle;
}

.checkbox-container input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid var(--highlightedText);
    border-radius: 3px;
    background-color: var(--background);
    margin-right: 8px;
    vertical-align: middle;
    cursor: pointer;
}

.checkbox-container input[type="checkbox"]:checked {
    background-color: var(--highlightedText);
}

.checkbox-container input[type="checkbox"]::before {
    content: "";
    display: block;
    width: 6px;
    height: 12px;
    border: solid var(--background);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 4px;
    opacity: 0;
}

.checkbox-container input[type="checkbox"]:checked::before {
    opacity: 1;
    border-color: var(--background);
    background-color: var(--background);
}

.table-headers {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    font-family: helvetica;
    font-size: var(--fontBase);
}


.root {
    --cui-table-bg: #0E1A1F  !important;
}

.row-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-delete {
    margin-left: 10px;
    cursor: pointer;
    color: red;
}


/* Estilos para mantener el checkbox y el ícono de cierre flotantes */
.floating-actions {
    position: relative;
    /* Esto permitirá que los elementos dentro sean flotantes */
    text-align: right;
    white-space: nowrap;
}

.floating-actions input[type="checkbox"],
.floating-actions .close-icon {
    position: absolute;
    right: 10px;
    /* Ajusta la distancia desde la derecha */
    top: 50%;
    /* Para alinear verticalmente en el centro */
    transform: translateY(-50%);
    margin-left: 10px;
    z-index: 3333;
}

.floating-actions .close-icon {
    cursor: pointer;
    margin-left: 20px;
    /* Añadimos espacio entre el checkbox y el icono de cierre */
}



.table-container {
    position: relative;
    overflow-x: auto;
    /* Permite el scroll horizontal */
}

.delete-row-button{
    color: var(--highlightedText);
}

.delete-row-button:hover {
    color: var(--font);
}

.pagination-texts{
    color: var(--selectedColumns);
    font-family: 'IBM Plex Sans Devanagari';
}

.pagination-controls {
    color: var(--highlightedRow);
}

