/* Styling for Tabs and Add Tab */
.tabs-container {
  display: flex;
  align-items: center;
}

.tab {
  padding: 5px 6px;
  color: var(--highlightedText);
  margin-right: 5px;
  border: 1px solid var(--highlightedText);
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in-out;
  font-size: var(--fontBase);
  height: 3.89vh;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.tab.active {
  background-color: var(--highlightedText);
  color: #141414;
}

.tab:hover {
  background-color: #1e1e1e;
  color: var(--highlightedText);
  transform: scale(1.05);
}

.close-button {
  background-color: transparent;
  color: var(--highlightedText);
  border: none;
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #ff304f;
}

.add-button {
  border: 1px solid var(--highlightedText);
  width: 2.19vw;
  background-color: var(--background);
  color: var(--highlightedText);
  font-size: 18px;
  padding: 2px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-button:hover {
  background-color: var(--highlightedText);
  color: #141414;
}

.error-message {
  background-color: rgba(255, 101, 4, 0.1);
  /* Slight orange tint */
  border: 2px solid #FF6504;
  /* Cyberpunk orange */
  padding: 20px;
  font-family: 'Orbitron', sans-serif;
  /* Cyberpunk font */
  letter-spacing: 1px;
  box-shadow: 0 0 10px #FF6504;
  animation: flicker 1.5s infinite alternate;
}

@keyframes flicker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.button-container .d-flex {
  display: flex;
  align-items: center;
}

.mx-2 {
  margin-left: 10px;
  margin-right: 10px;
}

.pagination-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto Mono', monospace;
  font-size: var(--fontBase);
}

.pagination-info span {
  margin-right: 10px;
}

.sql-query-input {
  flex-grow: 1;
  background-color: var(--flexFormsInput) !important;
  color: var(--highlightedText) !important;
  border: 1px solid var(--highlightedText)!important;
  border-left: 0px !important;
  border-right: 0px !important;
  height: 4vh;
  font-size: var(--fontMedium) !important;
}

.sql-query-input::placeholder{
  color: var(--highlightedText);
  opacity: 0.5;
}


.query-form-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.sql-query-builder {
  height: 4vh;
  border-radius: 3px 0px 0px 3px;
  background-color: var(--flexFormsInput);
  border: 1px solid var(--highlightedText);
  border-right: 0px;
  color: var(--highlightedText);
  display: flex;
  align-items: center;
}

.sql-query-builder:hover {
  background-color: var(--highlightedText);
  color: var(--font);

}

.sql-query-run {
  height: 4vh;
  border-radius: 0px 3px 3px 0px;
  background-color: var(--highlightedText);
  color: var(--flexFormsInput);
  border: 1px solid var(--highlightedText) !important;
  border-left: 0px !important;
  display: flex;
  align-items: center;
  font-size: var(--fontBase);
}

.sql-query-run:hover {
  background-color: var(--flexFormsInput);
}

.sql-query-run-loading {
  background-color: var(--flexFormsInput) !important;
  opacity: 1 !important;

}

.tab span {
  font-size: var(--fontBase);
}

.pointer {
  cursor: pointer;
}