.quicksight-dashboard {
    transition: width 1.5s ease, transform 1.5s ease;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.up {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.down {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
}

.graph-row{
    height: 35vh;
    display: flex;
    gap: 2vw;
}


.color-label {
    width: 20px !important;
    height: 21px;
}

.line-chart-container {
    min-height: 100%;
    width: 95%;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
}

.chart-container>.chart-wrapper {
    height: 70%;
}

.line-chart-container>.chart-wrapper {
    width: 100%;
    height: 90%;
} 

.bg-charts-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 1px solid var(--font);
    padding: 15px 0px;
}

.agents-box{
    justify-content: start;
    padding-left: 5px;
    border: 1px solid var(--highlightedText);
}


.thick-corners {
    --b: 3px; /* thickness of the border */    
    --c: var(--font); /* color of the border */    
    --w: 15px; /* width of border */    
    border: var(--b) solid #0000; /* space for the border */    
    --_g: var(--background) 90deg, var(--c) 0;
    --_p: var(--w) var(--w) border-box no-repeat;
    background:
        conic-gradient(from 90deg at top var(--b) left var(--b), var(--_g)) 0 0 / var(--_p),
        conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 / var(--_p),
        conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% / var(--_p),
        conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g)) 100% 100% / var(--_p);

    width: 38%;
}

