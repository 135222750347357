/* Modal Background and Border */


.modal {
    align-items: center;
    justify-content: center;
}

/* Modal Header */
.modal-header {
    background-color: var(--flexFormsInput);
    color: var(--highlightedText);
    border-bottom: 1px solid var(--highlightedText);
    padding: 10px;
    font-family: 'IBM Plex Sans';
}

.modal-title {
    color: var(--highlightedText);
    font-family: 'IBM Plex Sans';
    font-weight: 600;
    font-size: var(--fontMedium);
}

.cforminput {
    background-color: var(--flexFormsInput);
    border: none;
    border-radius: 3px;
    color: var(--highlightedText) !important;
}

.cforminput:focus {
    background-color: var(--flexFormsInput) !important;
    border: none;
    border-radius: 3px;
}

.cforminput::placeholder {
    color: var(--highlightedText);
    opacity: 1;
}

/* Close Button */
button.close {
    background-color: transparent;
    border: none;
    color: var(--highlightedText);
    font-size: var(--fontMedium);
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    transition: color 0.3s ease;
}

button.close:hover {
    color: #FF8F35;
}

/* Dropdowns and Inputs */


.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: var(--cui-modal-padding);
    max-height: 77vh;
}

/* React Select Styling */
.react-select__control {
    background-color: var(--background) !important;
    border: 2px solid var(--highlightedText) !important;
    color: var(--highlightedText) !important;
}

.react-select__option {
    background-color: var(--background) !important;
    color: var(--highlightedText) !important;
    padding: 10px;
}

.react-select__option:hover,
.react-select__option--is-focused {
    background-color: var(--highlightedText) !important;
    color: var(--background) !important;
    /* Orange background with dark text on hover */
}

/* Add Clause Button - Create Space Between Sections */
.add-clause {
    margin-top: 15px;
    /* Adds space between sections */
}

/* Align "-" Button */
.remove-clause-button {
    background-color: var(--highlightedText);
    color: var(--background);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    /* Ensure alignment with form fields */
    vertical-align: middle;
}

/* Alias input for aggregate */
.alias-input {
    background-color: var(--background);
    color: var(--highlightedText);
    border: 2px solid var(--highlightedText);
    padding: 8px;
    font-family: 'Roboto Mono', monospace;
    font-size: var(--fontBase);
    width: 25%;
    margin-left: 10px;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
}

/* Buttons */
.cbutton,
.modal-footer .cbutton {
    background-color: var(--flexFormsInput);
    color: var(--highlightedText);
    padding: 10px 20px;
    border-radius: 3px;
    font-family: 'Roboto', sans-serif;
    font-size: var(--fontBase);
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.cbutton:hover {
    background-color: #FF8F35;
    color: var(--background);
    /* Blue text */
}

.cbutton {
    border: 1px solid var(--highlightedText);
}


/* Modal Footer - Button Alignment */
.modal-footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid var(--highlightedText);
    background-color: var(--background);
    width: 100%;
    align-items: center;
    gap: 5px;
}

/* Focus States */
input:focus,
.react-select__control--is-focused,
.cformselect:focus {
    outline: none;
}

/* Date Picker */
.date-picker,
.react-datepicker__input-container input {
    background-color: var(--background) !important;
    color: var(--highlightedText) !important;
    border: 1px solid var(--highlightedText) !important;
    padding: 10px !important;
    width: 100% !important;
    font-family: 'Roboto Mono', monospace !important;
    font-size: var(--fontBase) !important;
    text-align: center;
}

.react-datepicker__input-container input::placeholder {
    color: var(--highlightedText) !important;
}

.date-picker input {
    background-color: var(--background) !important;
    color: var(--highlightedText) !important;
}

.date-picker:focus,
.react-datepicker__input-container input:focus {
    border-color: var(--highlightedText) !important;
    outline: none !important;
    background-color: var(--flexFormsInput);
}

.form-select {
    --cui-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23FF6504%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e") !important;
}



/* SelectStyles.css */



.cformselect__menu {
    background-color: var(--flexFormsInput) !important;
    border: none !important;
    border-radius: 4px;
    z-index: 1000;
}

.cformselect__option {
    /*     background-color: #142A33 !important;
 */
    cursor: pointer;
    padding: 10px;
    transition: background-color 0.2s ease;
}

.cformselect__option:hover {
    background-color: var(--highlightedText) !important;
    color: black;
}

.cformselect__option--is-selected {
    background-color: #142A33;
}

.cformselect__multi-value {
    background-color: var(--selectedColumns) !important;
    border-radius: 6px !important;
}

.cformselect__multi-value__label {
    color: #BAD8E9 !important;

}

.cformselect__multi-value__remove {
    color: var(--background) !important;
}

.cformselect__multi-value__remove:hover {
    background-color: #33333300 !important;
    transform: scale(1.3);
    color: var(--background) !important;
}

.cformselect__placeholder {
    color: var(--highlightedText) !important;
}

.cformselect__single-value {
    color: var(--highlightedText) !important;
}

.cformselect__dropdown-indicator {
    color: var(--highlightedText) !important;
}

.cformselect__dropdown-indicator:hover {
    color: var(--highlightedText) !important;
}

.cformselect__clear-indicator {
    color: var(--highlightedText) !important;
}

.cformselect__indicator-separator {
    background-color: var(--highlightedText) !important;
}


/* Contenedor principal del DatePicker */
.react-datepicker {
    left: 18%;
    background-color: var(--background);
    color: var(--highlightedText);
    border: 1px solid var(--highlightedText);
    border-radius: 3px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

/* Encabezado del DatePicker (mes y año) */
.react-datepicker__header {
    background-color: var(--background);
    color: var(--highlightedText);
    border-bottom: 1px solid var(--highlightedText);
}

.react-datepicker__current-month {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    color: var(--highlightedText);
}

/* Día de la semana en el encabezado */
.react-datepicker__day-name {
    color: var(--highlightedText);
    font-size: var(--fontBase);
}

/* Días del calendario */
.react-datepicker__day {
    color: var(--highlightedText);
    font-family: 'Roboto Mono', monospace;
    padding: 8px;
    border-radius: 50%;
    transition: background-color 0.2s ease, color 0.2s ease;
}

/* Días de fin de semana */
.react-datepicker__day--weekend {
    color: var(--highlightedText);
}

/* Días fuera del mes actual */
.react-datepicker__day--outside-month {
    color: rgba(255, 255, 255, 0.3);
}

/* Día seleccionado */
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background-color: #FF8F35;
    color: var(--background);
    font-weight: bold;
    border-radius: 3px;
}

/* Día actual */
.react-datepicker__day--today {
    border: 1px solid var(--highlightedText);
    font-weight: bold;
}

/* Botones de navegación (anterior y siguiente) */
.react-datepicker__navigation {
    background-color: var(--background);
}

.react-datepicker__navigation-icon {
    color: var(--highlightedText);
}

/* Botón de navegación en hover */
.react-datepicker__navigation:hover .react-datepicker__navigation-icon {
    color: #FF8F35;
}

/* Caja de tiempo (horas y minutos) */
.react-datepicker__time-container {
    background-color: var(--background);
    border-left: 1px solid var(--highlightedText);
    color: var(--highlightedText);
    border-radius: 0px 3px 0px 0px;
}

.react-datepicker__time-box {
    background-color: var(--background);
    color: var(--highlightedText);
}

.react-datepicker__time-list-item {
    font-family: 'Roboto Mono', monospace;
    color: var(--highlightedText);
    padding: 8px;
    transition: background-color 0.2s ease;
}

/* Hora seleccionada en la lista */
.react-datepicker__time-list-item--selected {
    background-color: #FF8F35;
    color: var(--background);
    font-weight: bold;
}

/* Hover sobre los elementos de fecha y tiempo */
.react-datepicker__day:hover,
.react-datepicker__time-list-item:hover {
    background-color: #FF8F35 !important;
    color: var(--background);
}

/* Placeholder y foco en los campos de entrada */
.react-datepicker__input-container input::placeholder {
    color: var(--highlightedText);
}

.react-datepicker__input-container input:focus {
    border-color: var(--highlightedText);
    outline: none;
}

.react-datepicker-time__header {
    color: var(--highlightedText);
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
    color: var(--highlightedText);
    fill: var(--highlightedText);
}


.react-datepicker__time-list::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

.react-datepicker__time-list::-webkit-scrollbar-track {
    background: #333333;
    /* Cambia este color a uno más oscuro si prefieres */
    border-radius: 3px 0px 3px 3px !important;
    border-right: 1px solid var(--highlightedText);
}

.react-datepicker__time-list::-webkit-scrollbar-thumb {
    background: var(--highlightedText);
    /* O usa otro color si quieres destacarlo */
    border-radius: 3px !important;
}

.react-datepicker__time-list::-webkit-scrollbar-corner {
    background: var(--background);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    border-bottom-right-radius: 3px !important;
}

/* new css */
.modal-content {
    background-color: var(--flexForms);
    color: var(--highlightedText);
    border: 1px solid var(--highlightedText);
    border-radius: 3px;
    font-size: var(--fontBase);
    width: 29vw !important;

    .cforminput {
        background-color: var(--flexFormsInput);
        border: none;
        border-radius: 3px;
    }
}

textarea,
.cformselect,
.cforminput {
    background-color: var(--background);
    color: var(--highlightedText);
    padding: 5px;
    font-family: 'Roboto Mono', monospace;
    font-size: var(--fontBase);
    border-radius: 0px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.custom-textarea {
    border: none;
}

.cformselect {
    border: 1px solid var(--highlightedText);
}

.cformselect__control {
    background-color: var(--background) !important;
    color: var(--highlightedText) !important;
    border: 1px solid var(--highlightedText) !important;
    min-height: 40px;
    border-radius: 0px !important;
    transition: border-color 0.3s ease;
    box-shadow: none !important;
}

.cformselect__control:hover {
    border-color: #ff6504 !important;
}

.builder-section {
    background-image: linear-gradient(to right, var(--selectedColumns) 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 8px 2px;
    background-repeat: repeat-x;
    padding-bottom: 10px;
}

.button-selected {
    background-color: var(--highlightedText);
    color: white;
}

.footer-buttons {
    font-family: 'Roboto Mono', monospace;
    font-size: var(--fontBase);
    padding: 5px;
    background-color: var(--background);
    color: var(--highlightedText);
    width: 16%;
    border-radius: 3px;
    border: 1px solid var(--highlightedText);
}

.use-button {
    background-color: var(--highlightedText);
    color: #FFFFFF;
}

.add-section-scroll {
    max-height: 80%;
    overflow: auto;
    margin-top: 6px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-right: 5px;
}

.clause-section,
.aggregate-section {
    background-color: var(--background);
    padding: 6px;
}

.aggregate-section {
    display: flex;
    flex-direction: column;
    background: var(--background);
}

.remove-button {
    border: none;
    background-color: var(--background);
    color: red;
    font-size: 17px;
    line-height: 13px;
}

.remove-button:hover {
    color: rgb(115, 4, 4);
}

.timestamp-container {
    width: 50%;
    display: flex;
}

.date-picker {
    width: 80% !important;
    border-radius: 3px 0px 0px 3px !important;
}

.time-picker {
    width: 20% !important;
    border-radius: 0px 3px 3px 0px !important;
    border-left: 0px !important;
}

.react-datepicker-popper {
    z-index: 9999 !important;
}

.and-or-selector {
    width: 33%;
    line-height: 20px;
    padding-inline-start: 6px;
}

.distinct {
    font-family: 'IBM Plex Mono';
    color: white;
    font-size: var(--fontBase);

}

.distinct-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;
}

.distinct-checkbox-input {
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: #e36414;
    /* Color naranja */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;
    position: relative;
    transform: scale(1.2);
    margin-left: 2px;
}

.distinct-checkbox-input:checked {
    background-color: #e36414;
    /* Sigue siendo naranja */
}

.distinct-checkbox-input:checked::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: black;
    display: block;
    margin: auto;
}

.cformselect::placeholder {
    color: white;
    opacity: 1;
}
