@import url('https://use.typekit.net/ajb2yxj.css');
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-5');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Sans+Devanagari:wght@100;200;300;400;500;600;700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.app {
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.landing-background {
    background-image: url('./assets/img/background-img.png');
    background-size: cover;
    background-position: center;
}

#root {
    background-color: var(--background);
    color: var(--font);
    font-family: Helvetica;
}

.side-menu {
    width: 17vw;
    height: 100vh;
}

/* .side-menu:first-child {
    height: 100%;
} */

.min-w-max {
    min-width: max-content;
}

.main-content {
    width: 75vw;
    height: 100vh;
    margin: 0vw 4vw;
    display: flex;
    flex-direction: column;
}

:root {

    --background: #0E1A1F;
    --menuBackground: #112229;
    --font: #f1fff1;
    --deselected: #2E3D42;
    --flexForms: #142A33;
    --flexFormsInput: #193845;
    --menuButtons: #242424;
    --highlightedText: #EA661B;
    --highlightedRow: #D47742;
    --selectedColumns: #3F6978;
    --trasparent: #ffffff00;
    --fontSmall: 0.73vw;
    /* 14px */
    --fontBase: 0.85vw;
    /* 16px */
    --fontMedium: 1.15vw;
    /* 22px */
    --fontLarge: 2.29vw;
    /* 44px */
    --fontExtraLarge: 3.33vw;
    /* 64px */

}

::-webkit-scrollbar {
    width: 7px;
    /* Ancho de la barra vertical */
    height: 7px;
    /* Altura de la barra horizontal */
}

/* Estilo para la pista de la barra de desplazamiento */
::-webkit-scrollbar-track {
    background: #ea671b37;
    border-radius: 6px;
    /* Color de fondo de la pista */
}

/* Estilo para el "dedo" de la barra de desplazamiento */
::-webkit-scrollbar-thumb {
    background: var(--highlightedText);
    /* Color del "dedo" */
    border-radius: 6px;
    /* Radio del borde del "dedo" */
}

::-webkit-scrollbar-corner {
    background: var(--background);
}




.form-select:focus {
    border-color: none !important;
    box-shadow: var(--highlightedText) !important;

}


.btn-cancel {
    background-color: var(--background) !important;
    color: var(--highlightedText) !important;
    border: 1px solid var(--highlightedText) !important;
    border-radius: 2px !important;
}

.disabled {
    background-color: var(--flexFormsInput) !important;
    color: var(--font) !important;
    border: 0px !important;
    border-radius: 2px !important;
}

.scroll {
    overflow: auto;
}

div:where(.swal2-container) div:where(.swal2-popup) {
    background-color: var(--background) !important;
    color: var(--highlightedText) !important;
    border-top: 2px solid var(--highlightedText) !important;
    border-left: 2px solid var(--highlightedText) !important;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0% 100%);
}

.logs-loading {
    height: 78vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm) {
    background-color: var(--highlightedText) !important;
    border: 0px !important;
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm):focus-visible {
    box-shadow: 0 0 0 0 !important;
}

div:where(.swal2-icon).swal2-error {
    border-color: var(--highlightedText) !important;
    color: var(--highlightedText) !important;
}

div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line] {
    background-color: var(--highlightedText) !important;
}

div:where(.swal2-icon).swal2-success .swal2-success-ring {
    border: .25em solid var(--highlightedText) !important;
}

div:where(.swal2-icon).swal2-success [class^=swal2-success-line] {
    background-color: var(--highlightedText) !important;
}

div:where(.swal2-container) div:where(.swal2-loader) {
    border-color: var(--highlightedText) rgba(0, 0, 0, 0) var(--highlightedText) rgba(0, 0, 0, 0) !important;
}

div:where(.swal2-icon).swal2-info {
    border-color: var(--highlightedText) !important;
    color: var(--highlightedText) !important;
}

div:where(.swal2-icon).swal2-warning {
    border-color: var(--highlightedText) !important;
    color: var(--highlightedText) !important;
}

.swal-title,
.swal2-html-container {
    font-family: 'IBM Plex Sans' !important;
}

div:where(.swal2-container) h2:where(.swal2-title) {
    font-family: 'IBM Plex Sans' !important;
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm) {
    font-family: helvetica !important;
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-cancel) {
    font-family: helvetica !important;
}

div:where(.swal2-container) input:where(.swal2-input),
div:where(.swal2-container) input:where(.swal2-file),
div:where(.swal2-container) textarea:where(.swal2-textarea) {
    border: 1px solid var(--highlightedText) !important;

}

div:where(.swal2-container) input:where(.swal2-input):focus,
div:where(.swal2-container) input:where(.swal2-file):focus,
div:where(.swal2-container) textarea:where(.swal2-textarea):focus {
    box-shadow: none !important
        /* inset 0 1px 1px rgba(0, 0, 0, .06), 0 0 0 3px var(--highlightedText) !important */
    ;
}

:where([data-sonner-toast][data-styled="true"]) {
    background-color: var(--highlightedText) !important;
    border: 0px !important;
}