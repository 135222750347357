.flex-form {
    position: absolute;
    z-index: 3;
    display: flex;
    justify-content: flex-end;
    width: 42%;
    height: fit-content;
    bottom: 0;
    flex-direction: column;
    align-items: flex-end;
    color: var(--highlightedText);
    right: 4vw;
}

.form-body {
    background-color: var(--flexForms);
    border: 2px solid var(--highlightedText);
    border-bottom: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 74vh;
}

.form-title {
    color: var(--highlightedText); 
    background-color: var(--flexFormsInput);   
    padding: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'IBM Plex Sans';
    font-weight: 600;
}

.flex-form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 72%;
    margin-bottom: 30px;
}

.flex-form-group > div > button {
    width: 26%;
    padding: 9px 0px;
}

.flex-form-input {
    width: fit-content;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    padding-right: calc(45% / 2);
    gap: 5px;
    color: var(--font);
    font-family: "IBM Plex Sans";
    align-items: baseline;
    margin-bottom: 40px;
    font-size: var(--fontBase);
}

.flex-form-input input {
    background-color: var(--flexFormsInput);
    width: 100%;
    border: none;
    font-family: "IBM Plex Mono";
    color: var(--highlightedText);
    font-size: var(--fontBase);
}

.flex-form-input input::placeholder {
    color: var(--highlightedText);
}

.flex-form-progress {
    background-color: var(--flexFormsInput);
    border-top: 3px solid var(--highlightedText);
    min-height: 3vh;
    width: 100%;
}

.input-container {
    position: relative;
    width: 71%;
}

.eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: var(--highlightedText);
    z-index: 4;
    width: 20px;
    height: 20px;
}

.flex-form-input .input-container input {
    padding-right: 30px;    
}

.is-admin-checkbox input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid var(--highlightedText);
    border-radius: 3px;
    background-color: var(--background);
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
}

.is-admin-checkbox input[type="checkbox"]:checked {
    background-color: var(--highlightedText); 
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="%23ffffff" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>');
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: center;
}

.is-admin-checkbox{
    gap: 0px;
    align-items: center;
}

.new-user-form{
    background-image: linear-gradient(to right, var(--highlightedText) 33%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-size: 8px 2px;
    background-repeat: repeat-x;

    display: flex;
    justify-content: center;
    padding-top: 30px;
    margin-bottom: 40px;
}

.new-user-form img {
    width: 55%;
}

/*-----------------------------------------------*/

/* .flex-form-dropdown {
    
} */

.input-container select {
    background-color: var(--flexFormsInput);
    border-radius: 0px;
    width: 100%;
    border: none;
    font-family: "IBM Plex Mono";
    color: var(--highlightedText);
    padding: 8px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: var(--fontBase);
}

.input-container select:focus {
    outline: none;
}

.input-container select option {
    background-color: var(--flexFormsInput);
    color: var(--highlightedText);
}

.flex-form-dropdown::after {
    content: '▼'; /* Chevron symbol */
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    color: var(--highlightedText); /* Cambia el color aquí */
    pointer-events: none; /* Para que no interfiera con el select */
}

.flex-form-dropdown:disabled {
    background-color: var(--flexFormsInput) !important;
    color: grey !important;
}


