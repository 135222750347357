.menu-header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 3vh;
    padding-left: 4vw;
    height: 17vh;
    justify-content: space-between;
    margin-top: 2vh;
    padding-left: 4vw;
    height: 17vh;
}

.menu-header:first-child div{
    display: flex;
    justify-content: space-between;
}

.menu-header:first-child label{
    color: var(--highlightedText);
    line-height: 1;
}

.logo {
    width: 20.78vw;
    height: 14vh;
}

.menu-tab{    
    cursor: pointer;
    height: 30%;
}

.menu-item{
    color: var(--font) !important;
    font-size: var(--fontBase);
    font-size: var(--fontBase);
    display: flex;
    align-items: center;
}

.down-menu{
    color: var(--highlightedText) !important;
}

.down-menu{
    color: var(--highlightedText) !important;
}

.submenu-item{
    color: var(--font) !important;
    font-size: var(--fontBase);
    padding: 0px !important;
    font-size: var(--fontBase);
    padding: 0px !important;
    line-height: 1;
    gap: 0px;
    gap: 0px;
}

.submenu-item:hover{
    color: var(--highlightedText) !important;
}

.selected {
    color: var(--highlightedText) !important;
    fill:  var(--highlightedText);
    fill: currentColor;
}

.unselected{
    color: var(--deselected) !important;
}

.dashed{
    color: var(--highlightedText);
    background-image: linear-gradient(to right, var(--highlightedText) 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 8px 2px;
    background-repeat: repeat-x;
}

.paddle {
    display: flex;
    transition: transform 1.5s ease;
    width: 100%;
    height: 78vh;
    margin-top: 3vh;
    background-color: var(--menuBackground);
}

.paddle.open {
    transform: translateX(0); 
}

.paddle:not(.open) {
    transform: translateX(-100%); 
}

.sidebar-nav .nav-group-toggle{
    padding: 0px;
}

.sidebar-nav .nav-group-toggle{
    padding: 0px;
}

.sidebar-nav .nav-group-toggle::after {    
    background-color: var(--trasparent);
    mask-image: none;   
}

.sidebar-nav{
    padding-left: 4vw;
    padding-top: 25px;
    display: flex;
    gap: 9px;
    overflow: hidden;
    max-height: 88%;
}

.sidebar{
    --cui-sidebar-width:100%;
    justify-content: space-between;  
    background-color: var(--menuBackground) !important;  
}

.down-sidebar {
    margin-top: 20px; /* Espacio entre menús */
    --cui-sidebar-width: 100%;
    justify-content: end;
    max-height: 35%;
    overflow-y: auto; /* Manejo de contenido grande */
    position: relative;
}


.collapside{
    border-top: 1px solid var(--highlightedText);
    border-right: 1px solid var(--highlightedText);
    min-width: 100%;
}

.sidebar-nav .nav-link{
    padding: 0px;
}

.sidebar-nav .nav-link{
    padding: 0px;
}

@media (hover: hover), (-ms-high-contrast:none){
    .sidebar-nav .nav-link:hover.nav-group-toggle::after {
        background-color: var(--trasparent);
        mask-image: none;
    }

    .sidebar-nav .nav-link:hover {
        color: var(--deselected);
        text-decoration: none;
        background: none;
    }
}


.nav-group-items {
    position: relative; /* Flujo normal del contenido */
    z-index: 10; /* Prioridad visual */
    background-color: var(--menuBackground);
    width: 100%;
}

.sidebar-nav .nav-group-items .nav-link {
    padding-left: 5px 
}

.menu-icon{
    margin-right: 0.7vw;
    width: 1.5vw;
}

.home-link{
    color: var(--highlightedText);
    line-height: 1;
    text-decoration: none;
}

.down-arrow{
    margin-left: 8px;
    color: white;
    fill: currentColor;
}

