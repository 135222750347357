.row-details {
  border: 2px solid var(--highlightedText);
  border-bottom: none;
  position: relative;
  background: #0f2e3936;
  z-index: 2;
  max-height: 58vh;
  min-height: 36vh;
  overflow-y: auto;
  padding: 10px;
  margin-top: 10px;
}

.details-title {
  color: var(--highlightedText);  
  background-image: linear-gradient(to right, var(--highlightedText) 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 8px 2px;
  background-repeat: repeat-x;
}

.details-content {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.detail-item {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  font-family: 'IBM Plex Sans';
  width: 100%;
  font-size: var(--fontBase);
  white-space: nowrap;
}

.detail-item label {
  font-family: 'IBM Plex Sans';
  background-color: #0F2E39;
  color: var(--highlightedText);
}

.detail-item span {
  overflow-x: hidden;
  font-family: 'IBM Plex Mono';
  min-height: 24px;
  cursor: pointer;
}

.close-button {
  float: right;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--highlightedText);
  font-size: var(--fontSmall);
}

.close-icon {
  transition: transform 0.7s ease-in-out;
}

.pruebaicon:hover {
  color: var(--highlightedText);
  transform: rotate(180deg);
}

.columns {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  width: 25%;
  padding: 10px;
}

.true-value{
  color: #0e671b;
}

.false-value{
  color: #ea121d;
}

.user-options{
  display: flex;
  flex-direction: column;
  width: 25%;
}



/* From Uiverse.io by cuzpq */ 
.theme-checkbox {
  margin: 0px 2px;
  --toggle-size: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 6.25em;
  height: 3.125em;
  background: linear-gradient(to right,#3F6978 50%, var(--highlightedText)  50%) no-repeat;
  background-size: 205%;
  background-position: 0;
  transition: 0.4s;
  border-radius: 99em;
  position: relative;
  cursor: pointer;
  font-size: var(--toggle-size);
}

.theme-checkbox::before {
  content: "";
  width: 2.25em;
  height: 2.25em;
  position: absolute;
  top: 0.438em;
  left: 0.438em;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.4s;
}

.theme-checkbox:checked::before {
  left: calc(100% - 2.25em - 0.438em);
}

.theme-checkbox:checked {
  background-position: 100%;
}
