.modules-header {    
    display: flex;
    justify-content: space-between;
    height: 2vh;
}

.modules-header label {
    color: var(--highlightedText);
    line-height: 1;
}

.breadcrumb-item a {
    text-decoration: none !important;
    color: var(--highlightedText) !important;
}

.breadcrumb-item+.breadcrumb-item::before {
    color: var(--highlightedText);
}

.breadcrumb{
    line-height: 1;
    margin: 0px
}