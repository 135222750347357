.table-search {
    background: none;
    border: none !important;
    color: var(--font);
    font-family: 'IBM Plex Mono';
}

.top-controls {
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    height: 15vh;
    justify-content: flex-end;
    justify-content: space-between;
/*     gap: 20px;
 */}

.table-filter {
    border: 1px solid var(--highlightedText);
    color: var(--highlightedText);
    margin-right: 10px;
    width: 2.19vw;
    height: 3.89vh;
    border-radius: 3px;
    padding: 2px;
    font-size: var(--fontBase);
}

.table-new-integration{
    border: 1px solid var(--highlightedText);
    margin-right: 10px;
    height: 3.89vh;
    border-radius: 3px;
    color: var(--highlightedText);
    font-size: var(--fontBase);
}

.table-text-filter {
    border: 1px solid var(--highlightedText);
    color: var(--highlightedText);
    margin-right: 10px;
    height: 42px;
    border-radius: 3px;
    padding: 2px 10px;
}

.table-text-filter:hover {
    color: var(--highlightedText) !important;
}

.export-dropdown {
    border-radius: 3px;
    height: 3.89vh;
    background-color: var(--background);
    color: var(--highlightedText);
    border: 1px solid var(--highlightedText);
    display: flex;
    align-items: center;
    font-size: var(--fontBase);

}

.export-dropdown-menu{
    background-color: var(--background);
    min-width: 0px;
    width: 100%;
    border: 1px solid var(--highlightedText);
}

.export-dropdown-item{
    text-align: center;
    background-color: var(--background);
    color: var(--highlightedText);
    font-size: var(--fontBase);
}

.export-dropdown-item:hover{
    color: var(--font);
    background-color: var(--highlightedText);
}

.filter-icon {
    width: 24px;
    height: 24px;
}

.search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;
}

.search-icon {
    color: var(--font);
    margin-right: 3px;
    height: 2vh;
}

.columns-select {
    font-family: 'IBM Plex Sans';
    background-color: var(--background);
    border: none;
    color: var(--highlightedText);
    max-width: 246px;
    text-align: end; 
    background-image: url(data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270%200%2016%2016%27%3e%3cpath%20fill=%27none%27%20stroke=%27%23EA661B%27%20stroke-linecap=%27round%27%20stroke-linejoin=%27round%27%20stroke-width=%272%27%20d=%27m2%205%206%206%206-6%27/%3e%3c/svg%3e);
    font-size: var(--fontBase);
}

#columnsMenu {
    overflow: scroll;
    height: 400px;
    position: absolute;
    background-color: var(--background);
    padding: 20px;
    overflow-x: hidden;
    z-index: 2;
}


option {
    background-color: var(--background);
}

.table-search::placeholder {
    color: var(--font);
    opacity: 1; /* Asegura que el color sea completamente visible */
}




.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.checkbox-container input {
    margin-right: 5px;
}

.checkbox-container {
    display: inline-block;
    vertical-align: middle;
}

.checkbox-container input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid var(--highlightedText);
    border-radius: 3px;
    background-color: var(--background);
    margin-right: 8px;
    vertical-align: middle;
    cursor: pointer;
}

.checkbox-container input[type="checkbox"]:checked {
    background-color: var(--highlightedText); 
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="%23ffffff" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>');
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: center;
}

.buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.button-style {
    background-color: #f7931e; /* Orange */
    color: #fff;
    border: 1px solid #f7931e;
    padding: 5px 10px;
    cursor: pointer;
}

.button-style:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.page-number {
    font-size: 18px;
    margin-left: 10px;
    color: #f7931e;
}

.sql-builder-container {
    margin-top: 20px;
    border: 1px solid #f7931e;
    padding: 10px;
    width: 100%;
}