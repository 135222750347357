.app{
  font-size: var(--fontBase);
}

.not-found-container {
  height: 96vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-found-container  > div:first-of-type {
  background-color: var(--background);
  color: var(--highlightedText);
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: Helvetica;
  gap: 21px;
}

.not-found-footer {
  height: 4vh;
  background-color: var(--background);
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-found-footer label {
  color: var(--highlightedText);
  font-size: var(--fontBase);
}

.not-found-icon {
  height: 166px;
}

.not-found-box {
  display: flex;
  gap: 14px;
  flex-direction: column;
  justify-content: flex-end;
}

.not-found-code {
  font-size: var(--fontExtraLarge);
  line-height: 59px;
}

.not-found-message {
  font-size: var(--fontMedium);
}
