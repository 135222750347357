.login-header {
    height: 18vh;
    display: flex;
    justify-content: space-between;
    width: 90%;
    z-index: 2;
    margin: 25px 73px 0px 73px;
}

.login-header div {
    color: var(--highlightedText);
    position: relative;
}

.login-container {
    position: absolute;
    z-index: 2;
    width: 100vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.c-app {
    height: 100vh;
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;
}

.form-control {
    background-color: var(--background);
    border: 3px solid var(--highlightedText);
    border-radius: 0px;
    width: 20vw;
    color: var(--font);
    font-size: var(--fontBase);
}

.form-control:focus {
    color: var(--font);
    background-color: var(--background);
    border-color: var(--highlightedText);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(216, 145, 86, 0);
}

.text-center {
    text-align: center !important;
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 20vw;
}

.container {
    width: 33vw;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;
}

.btn-solidarity {
    background-color: var(--highlightedText);
    border-radius: 0px;
    color: var(--font);
    z-index: 2;
}

.btn-solidarity:hover {
    background-color: var(--highlightedText);
    transform: scale(1.02);
}

.login-input {
    background-color: var(--background);
    border: 1px solid var(--highlightedText);
    border-radius: 0px;
    width: 20vw;
    text-align: center;
    caret-color: var(--highlightedText);
    z-index: 2;
    position: relative;

}

.login-logo {
    margin-bottom: 49px;
    margin-top: 36px;
}

.background-top {
    position: absolute;
    z-index: 1;
    width: 100vw;
    transition: transform 0.5s ease;
}

.background-bottom {
    position: absolute;
    z-index: 0;
    width: 100vw;
    transition: transform 0.5s ease;
}

.c-app.login-success .background-top {
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.c-app.login-success .background-bottom {
    transform: translateY(120%);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.c-app.login-success .text-center {
    transform: translateY(-50%);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.login-input::placeholder {
    color: white;
}

.login-input.empty.glow {
    box-shadow: 0 0 10px red;
}

.error-message{
    z-index: 2;
    color: red;
}

.shake {
    animation: shake 0.5s;
}

.show-pass-icon{
    cursor: pointer;
    color: var(--highlightedText);
    z-index: 4;
    width: 20px;
    height: 20px;
    position: relative;
    bottom: 52%;
    right: -41%;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px);
    }

    50% {
        transform: translateX(5px);
    }

    75% {
        transform: translateX(-5px);
    }

    100% {
        transform: translateX(0);
    }
}

